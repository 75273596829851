<template>
  <div class="bg-white m-3 p-3">
    <div v-if="!mounting && hasBadt">
      <div class="row">
        <div class="col-12">
          <div class="d-flex py-1 align-items-center">
            <span class="txt-black page-title">Bệnh nhân</span>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="flex justify-between items-center">
            <div class="md:w-1/3 w-1/2">
              <div class="input-group border bg-white rounded-xl border-gray-300">
                <span
                  class="input-group-text bg-white border-0 radius-10 font-size-20 cursor-pointer"
                  @click="searchPatient()"
                >
                  <i class="fa fa-search"></i>
                </span>
                <input
                  type="search"
                  v-model="search"
                  class="form-control bg-white h-10 mr-2"
                  placeholder="Tên, SĐT, CCCD, Số BHYT"
                  @keyup.enter="searchPatient"
                  @keyup.delete="searchPatientDelete"
                />
                <span
                  class="input-group-text bg-white border-0 radius-10 cursor-pointer"
                  :class="!search ? 'hidden' : ''"
                  @click="(search = ''), searchPatient()"
                >
                  <i class="fa fa-times-circle"></i>
                </span>
              </div>
            </div>
            <div class="flex">
              <ButtonHoDo
                @click="onShowModalAddPatient(true)"
                title="Thêm mới"
                class-name="bg-blue-700 text-white rounded-lg h-9 px-3 py-2"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="my-3 fs-14">
        Tổng số:
        <span class="font-bold">{{ patients.count }}</span> bệnh nhân
      </div>
      <div class>
        <div v-if="can('view-patient-list')" class="table-responsive">
          <table class="table table-vcenter table-mobile-md card-table table-scroll">
            <thead class="h-11 pb-2 border-b border-gray-300 fs-16 fw-600 text-gray-800">
              <tr>
                <th class="pl-md-2">Họ tên</th>
                <th class="text-center">Ngày sinh</th>
                <th class="text-center">SĐT</th>
                <th class="text-center">CCCD</th>
                <th class="text-center">Số BHYT</th>
                <th class="text-center">Trạng thái</th>
              </tr>
            </thead>
            <tbody
              v-if="patients && patients.count && !loading"
              id="patients"
              :class="patients && patients.count == 10 ? 'h-100' : ''"
            >
              <tr
                class="mt-2 h-16 hover:bg-gray-100 rounded-lg cursor-pointer"
                v-for="(patient, index) in patients.data"
                @click="goToDetail(patient)"
                :key="index + '-' + patient.id"
              >
                <td data-label="Thông tin bệnh nhân" class="pl-2">
                  <p class="mb-0">{{ patient.name }}</p>
                  <p class="fs-12 p-0 m-0">{{ patient ? patient.patient_code : "" }}</p>
                </td>
                <td
                  class="text-center"
                  data-label="Ngày sinh"
                >{{ patient.birthday ? formatDMY(patient.birthday) : "" }}</td>
                <td class="text-center" data-label="SĐT">
                  <p class="mb-0">{{ patient ? patient.phone : "" }}</p>
                  <p class="m-0">{{ patient ? patient.contact_phone : "" }}</p>
                </td>
                <td class="text-center" data-label="CCCD">{{ patient ? patient.national_id : "" }}</td>
                <td
                  class="text-center"
                  data-label="Số BHYT"
                >{{ patient ? patient.insurance_number : "" }}</td>
                <td class="text-center" data-label="Trạng thái"></td>
              </tr>
            </tbody>
            <tbody v-if="loading" id="patients">
              <tr v-for="i in 10" :key="'loading' + i">
                <td>
                  <div class="skeleton-avatar avatar-sm avatar-rounded"></div>
                </td>
                <td>
                  <div class="skeleton-line"></div>
                </td>
                <td>
                  <div class="skeleton-line"></div>
                </td>
                <td>
                  <div class="skeleton-line"></div>
                </td>
                <td>
                  <div class="skeleton-line"></div>
                </td>
              </tr>
              <tr>
                <td colspan="6"></td>
              </tr>
            </tbody>
          </table>
          <Pagination
            v-if="patients && patients.count && !loading"
            :items="patients"
            :current_page="patients.current_page"
            @onRefresh="getPersons"
          ></Pagination>
        </div>
        <div
          v-if="
            patients && (!patients.data || !patients.data.length) && !loading
          "
          class="row"
        >
          <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
            <img
              src="../../../public/assets/images/not-found-search.png"
              class="img-responsive p-0"
              alt
            />
            <p class="robo-16-400 text-black">
              {{
              search
              ? "HODO không tìm thấy kết quả phù hợp"
              : "Chưa có bệnh nhân nào"
              }}
            </p>
          </div>
        </div>
        <br />
      </div>
      <ModalAddPatient @complete="getPersons" />
    </div>
    <div v-if="!mounting && !hasBadt" class="d-flex align-items-center justify-content-center py-5">
      <p class="fs-16 fw-500 text-center">Chức này không khả dụng với Workspace hiện tại!</p>
    </div>
    <div v-if="mounting" class="d-flex align-items-center justify-content-center py-5">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import { ButtonHoDo } from '@/components/Button'
import Pagination from '../../components/Pagination'
import ModalAddPatient from '../../components/Workspace/ModalAddPatient.vue'
import _ from 'lodash'
import { APP_MODE } from '../../utils/constants'
export default {
  components: { Pagination, ModalAddPatient, ButtonHoDo },
  name: 'PatientList',
  data () {
    return {
      patients: {},
      loading: false,
      search: '',
      patient: null,
      mounting: false
    }
  },
  created () {
    if (
      _.isEmpty(this.$featureList) &&
      _.isEmpty(this.$featureWsList) &&
      _.isEmpty(this.$featureOrgList)
    ) {
      this.mounting = true
      setTimeout(() => {
        this.mounting = false
      }, 5000)
    } else {
      this.mounting = false
    }
  },
  mounted () {
    this.getPersons()
  },
  computed: {
    hasBadt () {
      return (
        (this.$featureList && this.$featureList['badt']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['badt']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['badt']
            : false) ||
        false
      )
    }
  },
  watch: {
    $featureList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    },
    $featureWsList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    },
    $featureOrgList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    }
  },
  methods: {
    searchPatient () {
      this.getPersons()
    },
    searchPatientDelete () {
      this.search = ''
      this.getPersons()
    },
    goToDetail (patient) {
      this.$router.push({ name: 'PatientDetail', params: { id: patient.id } })
    },
    onShowModalAddPatient (show) {
      window.$('#modal__add_patient').modal(show ? 'show' : 'hide')
    },
    async getPersons () {
      if (!this.$globalClinic) return
      let page = this.$route.query.page
      this.loading = true
      let params = {
        sort: 'created_at',
        sort_by: 'desc',
        page: page
      }
      if (this.search) {
        params.search = this.search
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getWorkspacePatients(this.$globalClinic?.id, params)
        .then(res => {
          this.patients = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDMY (d) {
      if (!d) return
      return window.moment(d).format('DD-MM-YYYY')
    }
  }
}
</script>
<style scoped>
.sort-both {
  background-image: url("../../../public/assets/images/icon/sort_both.png");
  background-repeat: no-repeat;
  background-position: center;
}

.sort-down {
  background-image: url("../../../public/assets/images/icon/sort_down.png");
  background-repeat: no-repeat;
  background-position: center;
}

.sort-up {
  background-image: url("../../../public/assets/images/icon/sort_up.png");
  background-repeat: no-repeat;
  background-position: center;
}

.center {
  display: flex;
  align-items: center;
}

.next {
  color: #b6c2cf;
}

.h-53 {
  height: 53px !important;
}

.txt-blue01 {
  color: #20419b;
}

.txt-green01 {
  color: #0e7c71;
}

.btn-connect {
  color: #20419b;
  background-color: #e4ecff;
  padding: 4px 9px;
  font-weight: bold;
  border-radius: 6px;
  margin: 2px 2px;
}

.btn-disconnect {
  color: #dc0026;
  background-color: #ffe8ec;
  padding: 4px 9px;
  font-weight: bold;
  border-radius: 6px;
  margin: 2px 2px;
}
thead th {
  background: #f9fafb !important;
  border-radius: 12px !important;
  border-bottom: none !important;
  margin-bottom: 4px !important;
}
</style>
